






































































import Vue from 'vue'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import CardTitle from '@/components/CardTitle.vue'
import PublishedTenderService from '@/services/publishedTender'
import TenderService from '@/services/tender'
import { TenderDTO, TenderOfferDTO } from '@/services/tender/types'
import TenderStatus from '@/views/Tender/partials/TenderStatus.vue'
import Timer from '@/components/Timer.vue'
import { outgoingDirection } from '@/constants/capacityDirections'
import LotBids from '@/views/PublishedTender/LotBids.vue'
import { errorMessages, isJsonString } from '@/utils/helpers'
import { ProcurementStatus } from '@/services/myTender/types'

export default Vue.extend({
  name: 'MakeSellingOffer',
  components: { LotBids, Timer, CardTitle, SubmitButton, CancelButton, TenderStatus },
  data: function (): {
    id: string
    procurement: TenderDTO
    data: TenderOfferDTO
    outgoingDirection: string[]
    ProcurementStatus: typeof ProcurementStatus
    loading: boolean
  } {
    const id = 'id' in this.$route.params && this.$route.params.id

    if (!id) {
      throw new Error('MakeSellingOffer is missing required route param id')
    }

    return {
      id,
      outgoingDirection,
      procurement: {
        id: 0,
        name: '',
        type: '',
        status: '',
        timeRemaining: 0,
        description: '',
        publishDate: '',
        endDate: '',
        publishTime: '',
        endTime: '',
        lots: [],
      },
      data: {
        procurementId: Number(id),
        offeredCbc: null,
        cbcDirection: null,
        lotBids: [],
      },
      ProcurementStatus,
      loading: true,
    }
  },
  methods: {
    async fetchItem(): Promise<void> {
      this.loading = true
      const loader = this.$loading.show()
      try {
        const response = await PublishedTenderService.find(this.id)
        if (response.data) {
          const procurementData = response.data.data
          this.procurement = procurementData
          this.data.lotBids = procurementData.lots.map((lot) => {
            return {
              ...lot,
              lotId: lot.id,
              offeredPower: 0,
              pricePerUnit: 0,
              isConditional: false,
              direction: lot.direction,
              cbcDirection: JSON.parse(lot.cbcCoverage ? (lot.direction as string) : '[]'),
              cbcOffered: lot.cbcCoverage ? lot.crossBorderCapacity?.leftoverCapacity : 0,
            }
          })
        }
      } catch (err) {
        let errorMessage: string = this.$t('somethingWentWrong') as string
        if (err.response.data) {
          errorMessage = errorMessages(err.response.data.message)
        }

        this.$toast.error(errorMessage)
        this.redirectBack()
      } finally {
        this.loading = false
        loader.hide()
      }
    },
    async redirectBack(): Promise<void> {
      await this.$router.push({ name: 'PublishedSellingTenders' })
    },
    async stringifyCbcDirections(): Promise<void> {
      this.data.lotBids.forEach((lotBid) => {
        if (lotBid.cbcDirection && !isJsonString(lotBid.cbcDirection as string))
          lotBid.cbcDirection = JSON.stringify(lotBid.cbcDirection)
      })
    },
    async parseCbcDirections(): Promise<void> {
      this.data.lotBids.forEach((lotBid) => {
        if (lotBid.cbcDirection && isJsonString(lotBid.cbcDirection as string))
          lotBid.cbcDirection = JSON.parse(lotBid.cbcDirection as string)
      })
    },
    async submit(): Promise<void> {
      await this.stringifyCbcDirections()
      const loader = this.$loading.show()
      try {
        const response = await TenderService.makeOffer(this.data)
        if (response) {
          this.$toast.success(response.data.message)
          await this.redirectBack()
        }
      } catch (err) {
        if (err.response.data) {
          this.$toast.error(errorMessages(err.response.data.message))
          return
        }
        this.$toast.error(this.$t('somethingWentWrong') as string)
      } finally {
        await this.parseCbcDirections()
        loader.hide()
      }
    },
  },
  mounted() {
    this.fetchItem()
  },
})
