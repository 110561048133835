
































































































































import { LotBidDTO } from '@/services/tender/types'
import { formatDate, renderDeliveryProfile, sortLots } from '@/utils/helpers'
import { defineComponent } from '@vue/composition-api'
import { VueEditor } from 'vue2-editor'

export default defineComponent({
  name: 'LotBids',
  setup() {
    return { formatDate, renderDeliveryProfile }
  },
  components: { VueEditor },
  props: {
    lotBids: {
      type: Array as () => LotBidDTO[],
      required: true,
    },
    capacityDirections: {
      type: Array as () => string[],
      required: true,
    },
  },
  model: {
    prop: 'lotBids',
  },
  computed: {
    computedLotBids: {
      get(): LotBidDTO[] {
        sortLots(this.lotBids)
        return this.lotBids
      },
      set(value: LotBidDTO[]): void {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    calculatedTotal(pricePerUnit: number, totalHours: number) {
      return pricePerUnit ? pricePerUnit * totalHours : 0
    },
  },
})
